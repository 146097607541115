@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.cn-formulario{
    position: fixed;
    width: 350px;
    bottom: 10px;
    right: 10px;
    border: 1px solid rgb(222, 222, 222);
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    z-index: 100000 !important;
}

.cn-form-group{
    width: 100%;
    margin-bottom: 10px;
}

.cn-titulo{
    font-family: "Open Sans", sans-serif;
    color: rgb(0, 75, 155);
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

.cn-subtitulo{
    font-family: "Open Sans", sans-serif;
    color: rgb(0, 75, 155);
    font-size: 14px;
    margin-bottom: 10px;
}

.cn-boton-cerrar{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(242, 242, 242);
    border-radius: 50%;
    color: gray;
    cursor: pointer;
    font-size: 16px;
}

.cn-textfield{
    font-size: 12px;
}

.cn-boton-cerrar:hover{
    background-color: rgb(223, 222, 222);
}

.cn-button-init{
    position: fixed;
    bottom: 15px;
    right: 15px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: rgb(0, 75, 155);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 25px;
    cursor: pointer;
}

.cn-button-init:hover{
    background-color: rgb(0, 35, 85);
}

.cn-parrafo{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.cn-respuesta-formulario{
    width: 270px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.MuiPopover-root{
    z-index: 100001 !important;
}

.cn-btn-init{
    position: fixed !important;
    bottom: 16px;
    right: 16px;
}

.cn-opciones{
    box-sizing: border-box;
    position: fixed !important;
    width: 250px;
    bottom: 70px;
    right: 16px;
    padding: 5px;
    z-index: 100000 !important;
}

.cn-option-text{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.cn-option{
    cursor: pointer;
}